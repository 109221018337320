<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        d="M23.9879 32.4672C23.6241 32.4672 23.2361 32.3217 22.9693 32.0307L8.41834 17.4797C7.86055 16.9219 7.86055 16.0004 8.41834 15.4183C8.97613 14.8606 9.89769 14.8606 10.4797 15.4183L23.9879 28.9507L37.5203 15.4183C38.0781 14.8606 38.9996 14.8606 39.5817 15.4183C40.1395 15.9761 40.1395 16.8977 39.5817 17.4797L25.0307 32.0307C24.7397 32.3217 24.3517 32.4672 23.9879 32.4672Z"
        fill="inherit"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-chevron-down-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
